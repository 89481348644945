import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import "../../OurServices/Logistics/Logistics.scss";
import "./Networks.scss";

const Networks = () => {
  return (
    <>
      <div className="logistics-con our-networks">
      {/* <p className="title">Affiliates</p> */}

      <div className="net-header-con">
        <p>OUR <span>NETWORK</span></p>
        <div className="net-header"></div>
      </div>


        <div className="d-flex flex-row service-ch-land">
          <div className="logistics-left w100p">
            <div className="logistics">
                <div className="logo-con">
                     <StaticImage
                      className="ch-img"
                      src="../../../images/affiliates/star-terminal.png"
                      width={100}
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="about us"
                    />
                </div>

              <div className="text">
                <p className="label">Star Terminal</p>
                <p>
                The STAR Group's transhipment arm with over 15,000 DWT capacity.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row service-ch-sea">
          <div className="logistics-right w100p">
            <div className="logistics">
            <div className="logo-con-noBg">
            <div className="logo-con" style={{ border: "none" }}>
                <StaticImage
                className="ch-img"
                src="../../../images/affiliates/batangueno.png"
                width={350}
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="about us"
              />
                </div>
            </div>

              <div className="text">
                <p className="label">Batangueño Port Services Inc.</p>
                <p>
                  A 3PL Container Service Provider located in the heart of the Port Area of Batangas City.
                </p>
                <br/>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row warehousing">
          <div className="logistics-left w100p">
            <div className="logistics">
             <div className="logo-con">
             <StaticImage
                  className="ch-img"
                  src="../../../images/aesi.png"
                  width={300}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                  style={{ marginTop: "3em" }}
                />
             </div>

              <div className="text" style={{ paddingTop: '3em' }}>
                <p className="label">Accur8 Enterprise Solutions Inc.</p>
                <p>
                AESi Automation Solutions is brought to you by Accur8 Enterprise Solutions Inc. that automtes and improves the operational efficiency of your port, terminal, cargo handling, warehouse, inventory tracking, payroll, and accounting.
                </p>
                <br />
                <p style={{ color: '#fff' }}>
                  <span>Visit here:  </span>
                  <a style={{ color: "#edcd1f" }} target="_blank" href="https://www.aesiph.com">https://www.aesiph.com</a>
                </p>
              </div>

              {/* <button className="see-more" onClick={() => setWH(!showWH)}>
                <div className="d-flex flex-row">
                  <div className="flex-60">
                    <span>See {showWH ? "Less" : "More"}</span>{" "}
                  </div>
                </div>
              </button> */}
            </div>
          </div>
        </div>

        <div className="d-flex flex-row service-ch-sea">
          <div className="logistics-right w100p">
            <div className="logistics">
            <div className="logo-con-noBg">
            <div className="logo-con" style={{ border: "none" }}>
                <StaticImage
                className="ch-img"
                src="../../../images/bintakay.png"
                width={200}
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="about us"
              />
                </div>
            </div>

              <div className="text">
                <p className="label">Bintakay Construction & Development Corp.</p>
                <p>Our very own Construction Arm.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row service-ch-land">
          <div className="logistics-left w100p">
            <div className="logistics">
                <div className="logo-con">
                     <StaticImage
                      className="ch-img"
                      src="../../../images/affiliates/fuel-tanker-outline.png"
                      width={800}
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="about us"
                    />
                </div>

              <div className="text">
                <p className="label">Fuel Tanker Carrier Inc.</p>
                <p>
                The STAR Group's liquid bulk carrier.
                </p>
                <br/>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-flex flex-row other-left">
          <div className="logistics-right">
            <div className="logistics">
              <StaticImage
                  className="ch-img"
                  src="../../../images/aesi.png"
                  width={300}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                  style={{ marginTop: "2em" }}
                />

              <div className="text" style={{ paddingTop: '0' }}>
                <p className="label">Accur8 Enterprise Solutions Inc.</p>
                <p>
                AESi Automation Solutions is brought to you by Accur8 Enterprise Solutions Inc. that automtes and improves the operational efficiency of your port, terminal, cargo handling, warehouse, inventory tracking, payroll, and accounting.
                </p>
                <br />
                <p>Visit here:  <a href="https://www.aesiph.com">https://www.aesiph.com</a></p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Networks;
